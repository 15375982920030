import { Button, Input, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { apiGet, apiPost } from "../../services/apiServices";
import Loader from "../Loader";
import moment from "moment";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (data, dataObj) => (
      <Tag color={dataObj.type === "DOWNLOAD" ? "orange" : "green"}>
        {dataObj.type}
      </Tag>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (data, dataObj) => <Tag>{dataObj.status}</Tag>,
  },
  {
    title: "Notify To",
    dataIndex: "notify_to",
    key: "notify_to",
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (data, dataObj) => {
      console.log("data created ", data);
      return moment.unix(dataObj.created_at).format("DD-MM-YYYY HH:mm A");
    },
  },
  {
    title: "Zip Link",
    dataIndex: "file_link",
    key: "file_link",
    render: (data, dataObj) => {
      console.log("data created ", data);
      return dataObj.file_link ? (
        <Button
          size="small"
          onClick={() => window.open(dataObj.file_link, "_")}
        >
          Download File
        </Button>
      ) : (
        "NA"
      );
    },
  },
];
export default function HistoryPage(props) {
  const [tableData, setTableData] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchHistory();
  }, []);
  const fetchHistory = async () => {
    setLoading(true);
    const response = await apiGet(
      `${process.env.REACT_APP_API_URL}/invoice/request/history`
    );
    if (response.status) {
      setTableData(response.data);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table dataSource={tableData} columns={columns} size="small" />
      )}
      {contextHolder}
    </div>
  );
}
