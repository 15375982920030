import React, { useState } from "react";
import Uploader from "../Uploader";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Input,
  Popconfirm,
  message,
} from "antd";
import Typography from "../Typography";
import Loader from "../Loader";
import { apiGet, apiPost, apiPostForm } from "../../services/apiServices";
import { PlusOutlined } from "@ant-design/icons";
import * as csv from "csvtojson";
import moment from "moment";

export default function RaiseRequest(props) {
  const [isLoading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [IRNNumber, setIRNNumber] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [irnData, setIRNData] = useState([]);
  const [needZip, setNeedZip] = useState(false);
  const [notificaitonEmail, setNotificationEmail] = useState("");
  const [irnFile, setIrnFile] = useState(null);

  const generateInvoice = async (invoiceNumber) => {
    setLoading(true);
    const response = await apiPost(
      `${process.env.REACT_APP_ASSET_API_URL}/generate_single/${invoiceNumber}`
    );
    console.log("irn response", response);
    if (response.status) {
      setGenerated(true);
      //   setIRNNumber("");
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };
  const handleViewInvoice = () => {
    window.open(`https://files.finkraft.ai/${IRNNumber}`);
    setIRNNumber("");
  };

  const handleUpload = async (file) => {
    setIrnFile(file);
  };

  const uploadSourceFileToS3 = async () => {
    let formData = new FormData();
    formData.append("file", irnFile);
    formData.append("filename", `${props.userInfo.id}_${moment().unix()}.csv`);
    formData.append("path", "invoce_generate_request_csv/");

    const response = await apiPostForm(
      `${process.env.REACT_APP_API_URL}/storage/objects/upload`,
      formData
    );
    if (response.status) {
      return response.data;
    } else {
      messageApi.error({
        type: "error",
        content: "Could not update credentials",
      });
    }
  };

  const createGenerateRequest = async () => {
    const fileData = await uploadSourceFileToS3();

    const response = await apiPost(
      `${process.env.REACT_APP_API_URL}/invoice/request`,
      {
        notify_to: notificaitonEmail,
        zip: needZip,
        csv_url: fileData.Location || "",
        type: "GENERATE",
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Request Created Successfully",
      });
      props.onClose && props.onClose();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not update credentials",
      });
    }
  };

  return (
    <div className="CreateGSTCredentials">
      {isLoading ? (
        <div style={{ height: 300 }}>
          <Loader />
        </div>
      ) : generated ? (
        <div>
          <Alert
            message="Invoice has been generated"
            type="success"
            showIcon
            action={
              <Button
                size="small"
                type="success"
                style={{ background: "#2ecc71", color: "white" }}
                onClick={handleViewInvoice}
              >
                View
              </Button>
            }
          />
          {/* <Button
            size="small"
            type="primary"
            style={{ marginTop: 24 }}
            onClick={generateInvoice}
          >
            Generate Again
          </Button> */}
          <Button
            size="small"
            type="primary"
            style={{ marginTop: 24 }}
            onClick={() => setGenerated(false)}
            icon={<PlusOutlined />}
          >
            Generate New
          </Button>
        </div>
      ) : irnFile ? (
        <div>
          <Input
            height={38}
            labelColor={"gray"}
            placeholder="Notification Email"
            onChange={(e) => setNotificationEmail(e.target.value)}
            style={{ marginTop: 12 }}
          />

          <Popconfirm
            title="Are you sure want to request zip file?"
            description="This will create an extra space in storage bucket and will be available for 7 days max"
            onConfirm={() => setNeedZip(true)}
            onCancel={() => setNeedZip(false)}
            okText="Yes"
            cancelText="No"
          >
            <Checkbox
              onChange={null}
              style={{ marginTop: 12 }}
              checked={needZip}
            >
              Do you need zip of this?
            </Checkbox>
          </Popconfirm>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 24,
              justifyContent: "flex-end",
            }}
          >
            <Button size="small" style={{ marginRight: 24 }}>
              Clear
            </Button>
            <Button size="small" type="primary" onClick={createGenerateRequest}>
              {" "}
              Create Request
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Input
            height={38}
            labelColor={"gray"}
            placeholder="Enter IRN"
            size="large"
            onPressEnter={(e) => generateInvoice(e.target.value)}
            onChange={(e) => setIRNNumber(e.target.value)}
            value={IRNNumber}
          />

          <Divider>
            <Typography style={{ color: "gray" }} variant="caption">
              OR
            </Typography>
          </Divider>

          <Uploader
            onSelect={handleUpload}
            placeholder="Drag & Drop list of IRN in csv for bulk generate max(100MB)"
          />
        </>
      )}
      {contextHolder}
    </div>
  );
}
