import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { apiGet } from "../../services/apiServices";
import { API_ENDPOINT_FETCH_INVOICE_DATA } from "../../constants/endpoint.constants";
import { get } from "lodash";
export default function DataCompare(props) {
  const containerRef = useRef(null);
  const [popupContent, setPopupContent] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);

  const handleAddPopups = async () => {
    if (containerRef.current) {
      const response = await apiGet(
        `${API_ENDPOINT_FETCH_INVOICE_DATA}${props.irnNo}`
      );
      // Attach click handlers to elements with specific IDs
      const clickableElements = containerRef.current.querySelectorAll("[id]");
      clickableElements.forEach((element) => {
        element.addEventListener("click", () =>
          showPopupHandler(response.data || {}, element.id)
        );
      });

      // Clean up function to remove event listeners when component unmounts
      return () => {
        clickableElements.forEach((element) => {
          element.removeEventListener("click", () =>
            showPopupHandler(element.id)
          );
        });
      };
    }
  };
  useEffect(() => {
    handleAddPopups();
  }, [props.htmlData]);

  const showPopupHandler = (invoiceInfo, id) => {
    let splitKey =
      id && id.includes("~")
        ? `itemlist[${id.split("~")[0]}]['${id.split("~")[1]}']`
        : id;

    let dataToShow =
      id && id.includes("~")
        ? get(
            invoiceInfo.data,
            `itemlist[${id.split("~")[0]}]['${id.split("~")[1]}']`
          )
        : invoiceInfo[id];
    setPopupContent(
      <div>
        <p style={{ fontSize: 14 }}>
          Data For <b>{`{{${invoiceInfo.template[id]?.key}}}`}</b>
        </p>

        <br />
        <p>{dataToShow || "No Data Available"}</p>
      </div>
    );
    setShowPopup(true);
  };

  const closePopupHandler = () => {
    setShowPopup(false);
  };

  return (
    <div
      className="htmlCompare"
      style={{ flex: 1, overflowY: "auto", background: "white" }}
    >
      <div
        style={{
          padding: 20,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Button
          type="primary"
          icon={<CloseOutlined />}
          danger
          onClick={props.closeView}
        >
          Close Debug {invoiceData ? "Mode" : ""}
        </Button>
      </div>

      {showPopup && (
        <div className="popup" style={popupStyles}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <span
              style={{
                cursor: "pointer",
                textAlign: "right",
                marginBottom: 12,
                fontSize: 24,
              }}
              onClick={closePopupHandler}
            >
              &times;
            </span>
          </div>
          <div style={{ marginTop: 12, fontWeight: 600 }}>{popupContent}</div>
        </div>
      )}
      <div
        className="HtmlPreview"
        style={{ marginTop: 24 }}
        dangerouslySetInnerHTML={{ __html: props.htmlData }}
        ref={containerRef}
      ></div>
    </div>
  );
}

const popupStyles = {
  display: "block",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "12px 20px",
  backgroundColor: "white",
  border: "1px solid #ccc",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  textAlign: "left",
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
};
