import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  ColorPicker,
  Dropdown,
  Input,
  Modal,
  Space,
  message,
} from "antd";
import "./LandingPage.scss";
import FinLogo from "../../../images/FinLogo.png";
import {
  FilePdfOutlined,
  CloudDownloadOutlined,
  UserOutlined,
  ClockCircleOutlined,
  QuestionOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { apiGet } from "../../services/apiServices";
import {
  API_ENDPOINT_CHECK_INVOICE,
  API_ENDPOINT_FETCH_INVOICE_HTML,
} from "../../constants/endpoint.constants";
import RaiseRequest from "../RaiseRequest";
import LoginPage from "../LoginPage";
import Typography from "../Typography";
import HistoryPage from "../HisotryPage";
import RaiseDownloadRequest from "../RaiseDownloadRequest";
import DataCompare from "../DataCompare";
export default function LandingPage(props) {
  const { Search } = Input;
  const [showModal, setShowModal] = useState("");
  const [pdfData, setPdfData] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [irnNumber, setIRNNumber] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showCompare, setShowCompare] = useState(false);
  const [compareHTML, setcompareHTML] = useState("");
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    if (localStorage.getItem("token")) fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    const response = await apiGet(
      `${process.env.REACT_APP_API_URL}/meta/user/info`
    );
    if (response.status) {
      setUserInfo({
        ...response.data,
        clusterId: response.data.cluster_id,
      });
    }
  };

  const searchGeneratedInvoice = async (textdata) => {
    if (!textdata) return;
    setLoading(true);
    try {
      const response = await apiGet(`${API_ENDPOINT_CHECK_INVOICE}${textdata}`);
      console.log("response is:", response);
      if (response.status) {
        window.open(`https://files.finkraft.ai/${textdata}`, "_blank");
        setIRNNumber("");
      } else {
        messageApi.error({
          type: "error",
          content: response.message,
        });
      }
    } catch (error) {
      messageApi.error({
        type: "error",
        content: "Invoice does not exist, please generate invoice instead",
      });
    }
    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setUserInfo(null);
  };
  const renderModalUI = () => {
    switch (showModal) {
      case "GENERATE":
        return (
          <RaiseRequest userInfo={userInfo} onClose={() => setShowModal("")} />
        );
      case "DOWNLOAD":
        return (
          <RaiseDownloadRequest
            userInfo={userInfo}
            onClose={() => setShowModal("")}
          />
        );
      case "LOGIN":
        return (
          <LoginPage
            onClose={() => {
              setShowModal(false);
              fetchUserInfo();
            }}
          />
        );
      case "HISTORY":
        return <HistoryPage />;
      default:
        return (
          <RaiseRequest userInfo={userInfo} onClose={() => setShowModal("")} />
        );
    }
  };

  const handleComparePDF = async (textdata) => {
    if (irnNumber) {
      const response = await apiGet(
        `${API_ENDPOINT_FETCH_INVOICE_HTML}${textdata}`
      );

      if (response.status) {
        setcompareHTML(response.data);
      }

      setShowCompare(true);
    } else {
      messageApi.error({
        type: "error",
        content: "Please enter IRN number for data compare",
      });
    }
  };
  return (
    <div className="LandingPage">
      <div className="TopHeader">
        <div className="AppLogo">
          <img src={FinLogo} alt="AppLogo" />
        </div>
        <div className="MenuContainer">
          {userInfo ? (
            <>
              <div className="MenuItem">
                <Button
                  size="small"
                  type="primary"
                  icon={<ClockCircleOutlined />}
                  onClick={() => setShowModal("HISTORY")}
                >
                  Request History
                </Button>
              </div>
              <div className="MenuItem">
                <Button
                  size="small"
                  type="primary"
                  icon={<FilePdfOutlined />}
                  onClick={() => setShowModal("GENERATE")}
                >
                  Generate Invoice
                </Button>
              </div>
              <div className="MenuItem">
                <Button
                  size="small"
                  type="primary"
                  icon={<CloudDownloadOutlined />}
                  onClick={() => setShowModal("DOWNLOAD")}
                >
                  Bulk Download
                </Button>
              </div>
            </>
          ) : null}
          <div
            className="MenuItem"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography style={{ marginRight: 6 }}>
              {userInfo?.email}
            </Typography>
            {!userToken ? (
              <Avatar
                style={{
                  backgroundColor: "#87d068",
                }}
                icon={<UserOutlined />}
                onClick={() => {
                  if (!userInfo) setShowModal("LOGIN");
                }}
              />
            ) : (
              <Dropdown
                menu={{
                  items: [
                    {
                      label: (
                        <a
                          href="#"
                          style={{ color: "#e74c3c" }}
                          onClick={handleLogout}
                        >
                          Logout
                        </a>
                      ),
                      key: "0",
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <Avatar
                      style={{
                        backgroundColor: "#87d068",
                      }}
                      icon={<UserOutlined />}
                      onClick={() => {
                        if (!userInfo) setShowModal("LOGIN");
                      }}
                    />
                  </Space>
                </a>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
      {showCompare ? (
        <DataCompare
          htmlData={compareHTML}
          closeView={() => {
            setIRNNumber("");
            setShowCompare(false);
          }}
          irnNo={irnNumber}
        />
      ) : (
        <div className="SearchBoxContainer">
          <div style={{ width: 600, display: "flex", alignItems: "center" }}>
            <Search
              placeholder="Search By IRN"
              allowClear
              onSearch={searchGeneratedInvoice}
              onChange={(e) => setIRNNumber(e.target.value)}
              height={54}
              value={irnNumber}
              style={{
                width: "100%",
              }}
              loading={isLoading}
              size="large"
            />
            <Button
              style={{ height: 40, marginLeft: 6 }}
              icon={<QuestionOutlined />}
              onClick={() => handleComparePDF(irnNumber)}
              danger
            />
          </div>
        </div>
      )}
      <Modal
        centered
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        width={showModal === "HISTORY" ? 1000 : 600}
        title={
          showModal === "GENERATE"
            ? "Generate Invoice"
            : showModal === "DOWNLOAD"
            ? "Download Invoices"
            : showModal === "HISTORY"
            ? "Request History"
            : "Login"
        }
      >
        {renderModalUI()}
      </Modal>
      {contextHolder}
    </div>
  );
}
