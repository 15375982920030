import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { apiPost } from "../../services/apiServices";

export default function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const response = await apiPost(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        email: email,
        password: password,
      }
    );
    if (response.status) {
      localStorage.setItem("token", response.data.token);
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.onClose && props.onClose();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };
  return (
    <div>
      <div>
        <Input
          height={38}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div style={{ marginTop: 12 }}>
        <Input
          height={38}
          placeholder="Passsword"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div style={{ marginTop: 24 }}>
        <Button type="primary" onClick={handleLogin} loading={isLoading}>
          Sign in
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
