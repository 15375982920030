import { Upload } from "antd";
import "./Uploader.scss";
import { CloudUploadOutlined } from "@ant-design/icons";
import Typography from "../Typography";
const { Dragger } = Upload;

export default function Uploader(props) {
  const beforeUploadHandler = () => {
    return false; // Allow default action
  };

  const draggerProps = {
    name: "file",
    multiple: true,
    beforeUpload: beforeUploadHandler,
    onChange(info) {
      const { status } = info.file;
      console.log("new file", info.file);
      props.onSelect && props.onSelect(info.file);
    },
    onDrop(e) {},
  };

  return (
    <Dragger
      {...draggerProps}
      style={{
        borderColor: props.colored ? "var(--base-color-blue)" : "gray",
        backgroundColor: props.colored ? "rgba(37, 117, 220, 0.04)" : "white",
        padding: "38px 0",
        cursor: "pointer",
        height: 260,
      }}
    >
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined
          style={{
            fontSize: 34,
            color: "#2575dc",
          }}
          fontSize="large"
        />
      </p>
      <Typography
        style={{
          textAlign: "center",
          color: "#2575dc",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props.placeholder}
      </Typography>
    </Dragger>
  );
}
