import React, { useState } from "react";
import Uploader from "../Uploader";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Input,
  Popconfirm,
  message,
} from "antd";
import Typography from "../Typography";
import Loader from "../Loader";
import { apiGet, apiPost, apiPostForm } from "../../services/apiServices";
import { PlusOutlined } from "@ant-design/icons";
import * as csv from "csvtojson";
import moment from "moment";

export default function RaiseDownloadRequest(props) {
  const [isLoading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [IRNNumber, setIRNNumber] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [irnData, setIRNData] = useState([]);
  const [needZip, setNeedZip] = useState(true);
  const [notificaitonEmail, setNotificationEmail] = useState("");
  const [irnFile, setIrnFile] = useState(null);

  const generateInvoice = async (invoiceNumber) => {
    setLoading(true);
    const response = await apiPost(
      `http://localhost:8000/generate_single/${invoiceNumber}`
    );
    console.log("irn response", response);
    if (response.status) {
      setGenerated(true);
      //   setIRNNumber("");
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };
  const handleViewInvoice = () => {
    window.open(`https://files.finkraft.ai/${IRNNumber}`);
    setIRNNumber("");
  };

  const handleUpload = async (file) => {
    setIrnFile(file);
  };

  const uploadSourceFileToS3 = async () => {
    let formData = new FormData();
    formData.append("file", irnFile);
    formData.append("filename", `${props.userInfo.id}_${moment().unix()}.csv`);
    formData.append("path", "invoce_generate_request_csv/");

    const response = await apiPostForm(
      `${process.env.REACT_APP_API_URL}/storage/objects/upload`,
      formData
    );
    if (response.status) {
      return response.data;
    } else {
      messageApi.error({
        type: "error",
        content: "Could not update credentials",
      });
    }
  };

  const createGenerateRequest = async () => {
    const fileData = await uploadSourceFileToS3();

    const response = await apiPost(
      `${process.env.REACT_APP_API_URL}/invoice/request`,
      {
        notify_to: notificaitonEmail,
        zip: needZip,
        csv_url: fileData.Location || "",
        type: "DOWNLOAD",
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Request Created Successfully",
      });
      props.onClose && props.onClose();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not update credentials",
      });
    }
  };

  return (
    <div className="CreateGSTCredentials">
      {isLoading ? (
        <div style={{ height: 300 }}>
          <Loader />
        </div>
      ) : irnFile ? (
        <div>
          <Input
            height={38}
            labelColor={"gray"}
            placeholder="Notification Email"
            onChange={(e) => setNotificationEmail(e.target.value)}
            style={{ marginTop: 12 }}
          />

          <Checkbox style={{ marginTop: 12 }} checked={needZip}>
            We'll send you zip file on above email*
          </Checkbox>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 24,
              justifyContent: "flex-end",
            }}
          >
            <Button size="small" style={{ marginRight: 24 }}>
              Clear
            </Button>
            <Button size="small" type="primary" onClick={createGenerateRequest}>
              {" "}
              Create Request
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Uploader
            onSelect={handleUpload}
            placeholder="Drag & Drop list of IRN in csv for bulk generate max(100MB)"
          />
        </>
      )}
      {contextHolder}
    </div>
  );
}
